import { Terminal } from '@/components/Terminal';
import { Terminal as XTerm } from 'xterm';
import { memo, useCallback, useEffect, useRef } from 'react';

type VMConnection = {
    socket: WebSocket;
    buffer: string;
};
export const VMTerminal = memo(({ uuid }: { uuid?: string }) => {
    const connectionsRef = useRef<Map<string, VMConnection>>();
    const terminalRef = useRef<XTerm>();
    const prevUuid = useRef<string>();

    const handleData = useCallback(
        (data: string) => {
            if (!uuid || !connectionsRef.current?.has(uuid)) return;
            connectionsRef.current.get(uuid)!.socket.send(data);
        },
        [uuid]
    );

    useEffect(() => {
        connectionsRef.current = new Map();
        return () => {
            if (!connectionsRef.current) return;
            connectionsRef.current.forEach((connection, key) => {
                connection?.socket.close();
            });
        };
    }, []);

    useEffect(() => {
        if (!uuid) return;
        if (prevUuid.current && connectionsRef.current?.has(prevUuid.current)) {
            if (terminalRef.current) {
                terminalRef.current.clear();
                terminalRef.current.write(
                    connectionsRef.current.get(uuid)?.buffer || ''
                );
            }
        }
        if (connectionsRef.current?.has(uuid)) return;
        const token = localStorage.getItem('token');
        const tokenObj = token ? JSON.parse(token) : { access: 'workshop' };
        const backend_ws_url = process.env.REACT_APP_API_URL?.replace(
            'http',
            'ws'
        );
        const socket = new WebSocket(
            backend_ws_url + `/ws/sandbox/ssh/${uuid}/`,
            ['defbox-ssh-websocket', tokenObj.access]
        );

        socket.onopen = function (e) {
            console.log(e);
        };

        socket.onmessage = function (event) {
            terminalRef.current!.write(event.data);
            if (connectionsRef.current) {
                connectionsRef.current.get(uuid)!.buffer += event.data;
                if (connectionsRef.current.get(uuid)!.buffer.length > 512) {
                    connectionsRef.current.get(uuid)!.buffer =
                        connectionsRef.current.get(uuid)!.buffer.slice(-512);
                }
            }
        };

        socket.onclose = function (event) {
            console.log('connections finished', event);
        };

        socket.onerror = function (error) {
            console.error(error);
        };
        connectionsRef.current?.set(uuid, {
            socket,
            buffer: '',
        });
    }, [uuid]);
    prevUuid.current = uuid;
    return (
        <Terminal
            onData={handleData}
            ref={(terminal) => (terminalRef.current = terminal!)}
        />
    );
});

VMTerminal.displayName = 'VMTerminal';
