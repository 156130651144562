import { useEffect, useContext } from 'react';
import { Outlet, useParams, useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '@/hooks/redux';
import { LoaderDots, NoData } from '@/components';
import { LAB_TYPE } from '@/constants';
import {
    getLabThank,
    getWsLabByUuidThunk,
} from '@/store/reducers/LabSlice/AsyncThanks';
import {
    getSchemeThank,
    getSchemeWsThunk,
} from '@/store/reducers/SchemeSlice/AsyncThanks';
import {
    getTemplateByIdThank,
    runTemplateThank,
} from '@/store/reducers/TemplatesSlice/AsyncThanks';
import {
    getTasksThunk,
    getTasksWsThunk,
} from '@/store/reducers/TasksSlice/AsyncThunks';
import {
    getFlagsThunk,
    getFlagsWsThunk,
} from '@/store/reducers/FlagsSlice/AsyncThunks';
import { labWithSchemeSlice } from '@/store/reducers/LabSlice';
import { Header, Sidebar, HelpBar } from './components';
import { StyledLab, StyledContainer } from './styled';
import { useStatusPolingRequest } from './hooks/useStatusPolingRequest';
import { StyledPageLoaderContainer } from '@/pages/styled';
import { LabContext } from './context';

export const Lab = () => {
    const { type } = useContext(LabContext);
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const { template_id, uuid } = useParams();
    const { lab, labState } = useAppSelector(
        (state) => state.LabWithSchemeSlice
    );
    const { tasks } = useAppSelector((state) => state.TaskSlice);
    const { currentTemplate, currentTemplateState } = useAppSelector(
        (state) => state.TemplatesSlice
    );
    const iCanRun = lab?.you_can_run.can_run;

    useEffect(() => {
        if (lab?.uuid) {
            switch (type) {
                case LAB_TYPE.DEFAULT:
                    dispatch(getFlagsThunk({ uuid: lab.uuid }));
                    dispatch(getSchemeThank({ uuid: lab.uuid }));
                    dispatch(getTasksThunk(lab.uuid));
                    return;
                case LAB_TYPE.WORKSHOP:
                    dispatch(getFlagsWsThunk(lab.uuid));
                    dispatch(getSchemeWsThunk(lab.uuid));
                    dispatch(getTasksWsThunk(lab.uuid));
            }
        }
    }, [lab, dispatch, type]);

    useEffect(() => {
        switch (type) {
            case LAB_TYPE.DEFAULT:
                dispatch(getTemplateByIdThank(Number(template_id)));
                dispatch(getLabThank({ templateId: Number(template_id) }));
                return;
            case LAB_TYPE.WORKSHOP:
                dispatch(getWsLabByUuidThunk(uuid || 'nouuid'));
        }
        return () => {
            dispatch(labWithSchemeSlice.actions.resetLab());
        };
    }, [template_id, type, dispatch, uuid]);

    useStatusPolingRequest({
        lab,
        template: currentTemplate,
        tasks,
    });

    const renderContentPage = () => {
        if (labState.fetching || currentTemplateState.fetching) {
            return (
                <StyledPageLoaderContainer style={{ minHeight: '100vh' }}>
                    <LoaderDots />
                </StyledPageLoaderContainer>
            );
        }
        if (
            !labState.fetching &&
            !currentTemplateState.fetching &&
            !lab &&
            currentTemplate?.you_can_run
        ) {
            return (
                <NoData
                    title="Run the lab for the first time"
                    buttons={[
                        {
                            title: 'run the lab',
                            action() {
                                if (currentTemplate)
                                    dispatch(
                                        runTemplateThank(currentTemplate.id)
                                    );
                            },
                        },
                    ]}
                />
            );
        }
        if (
            !labState.fetching &&
            !currentTemplateState.fetching &&
            !currentTemplate?.you_can_run
        ) {
            return (
                <NoData
                    title="The laboratory is unavailable."
                    text="This lab are available for premium users only"
                    buttons={[
                        {
                            title: 'Subscribe',
                            action() {
                                window.open(
                                    process.env.REACT_APP_PAYMENT_URL,
                                    '_blank'
                                );
                            },
                        },
                    ]}
                />
            );
        }
        return (
            <>
                <Header
                    disabled={lab?.you_can_run.description}
                    type={type}
                    fetchingTabs={labState.fetching}
                    fetchingTitle={currentTemplateState.fetching}
                    pathname={pathname}
                    iCanRun={iCanRun}
                    lab={lab}
                    currentTemplate={currentTemplate}
                />
                <StyledContainer>
                    <Sidebar>
                        <HelpBar
                            messageFetching={labState.fetching}
                            message={lab?.description ?? undefined}
                            firstBlood={lab?.first_blood}
                            lastSolved={lab?.last_solved}
                        />
                        <>{lab && <Outlet />}</>
                    </Sidebar>
                </StyledContainer>
            </>
        );
    };

    return (
        <StyledLab
            style={
                type === LAB_TYPE.WORKSHOP
                    ? { minWidth: '1280px', overflow: 'auto' }
                    : undefined
            }
        >
            {renderContentPage()}
        </StyledLab>
    );
};
