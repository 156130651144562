import { useState, FC, useEffect, ReactNode, CSSProperties } from 'react';
import { IconButton, LoaderDots } from '@/components';
import { SchevronDownIcon, SchevronUpIcon } from '../IconsV';
import { Input } from '../Input/Input/Input';
import {
    StyledBlock,
    StyledHeader,
    StyledTitle,
    StyledActionBar,
    StyledContent,
    StyledSearchArea,
    StyledLoaderContainer,
} from './styled';

interface IProps {
    id?: string;
    headerStyle?: CSSProperties;
    isOpen?: boolean;
    title: ReactNode;
    children?: ReactNode;
    actions?: ReactNode;
    isLoading?: boolean;
    placeholderSearch?: string;
    onSearch?: (arg: string) => void;
    onExpand?: (expant: boolean) => void;
}

export const Block: FC<IProps> = (props) => {
    const {
        id,
        title,
        headerStyle,
        isOpen: inputIsOpen = true,
        children,
        onSearch,
        actions,
        placeholderSearch,
        isLoading,
        onExpand,
    } = props;

    const [isOpen, setIsOpen] = useState(inputIsOpen);
    const isContent = isOpen && !isLoading;

    useEffect(() => {
        setIsOpen(inputIsOpen);
    }, [inputIsOpen]);

    const renderTitle = () => {
        if (typeof title === 'string') {
            return <StyledTitle>{title}</StyledTitle>;
        }
        return title;
    };

    return (
        <StyledBlock id={id}>
            <StyledHeader style={headerStyle}>
                {renderTitle()}
                {onSearch && (
                    <StyledSearchArea>
                        <Input
                            onChange={onSearch}
                            placeholder={placeholderSearch}
                        />
                    </StyledSearchArea>
                )}
                <StyledActionBar>
                    {actions}
                    <IconButton
                        icon={
                            isOpen ? <SchevronUpIcon /> : <SchevronDownIcon />
                        }
                        onClick={() => {
                            const expandState = !isOpen;
                            if (onExpand) onExpand(expandState);
                            setIsOpen(expandState);
                        }}
                    />
                </StyledActionBar>
            </StyledHeader>
            {isContent && <StyledContent>{null || children}</StyledContent>}
            {isLoading && (
                <StyledLoaderContainer>
                    <LoaderDots />
                </StyledLoaderContainer>
            )}
        </StyledBlock>
    );
};
