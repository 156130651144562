import { useState, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from '@/hooks/usePopper/usePopper';
import { PLACEMENTS } from '@/hooks/usePopper/constants';
import { useOutsideClick } from '@/hooks/useOutsideClick';
import { useCombinedRefs } from '@/hooks/useCombinedRef';
import { DropdownMenuProps, IDropdownMenuItem } from '../types';
import {
    StyledDropdownMenu,
    StyledDropdown,
    StyledDropdownContainer,
} from '../styled';
import { DropdownMenuItem } from './DropdownMenuItem';

export const DropdownMenu = ({
    list = [],
    onClick,
    onClose,
    toggleOpen,
    renderItem,
    children,
    style,
    styleContainer,
    styleItemsContainer,
    disabled,
    placement,
    offset,
    matchWidth,
    isOpen: inputIsOpen,
    flip = false,
}: DropdownMenuProps) => {
    const [isOpen, setIsOpen] = useState<boolean | undefined>(inputIsOpen);

    useEffect(() => {
        setIsOpen(inputIsOpen);
    }, [inputIsOpen, setIsOpen]);

    const { referenceRef, popperRef } = usePopper({
        enabled: true,
        placement: placement || PLACEMENTS.bottomLeft,
        flip,
        offset: offset || 4,
        arrowPadding: 0,
        matchWidth: matchWidth || false,
    });

    const dropdownRef = useCombinedRefs<HTMLDivElement | null>(referenceRef);

    useOutsideClick(dropdownRef, (e) => {
        if (onClose) {
            onClose(e);
        } else {
            setIsOpen(false);
        }
    });

    const onClickHandler = (id: IDropdownMenuItem['id']) => {
        if (onClick) onClick(id);
    };

    const renderOverlay = () => {
        if (isOpen && dropdownRef.current && !disabled) {
            return createPortal(
                <StyledDropdownContainer ref={popperRef} style={styleContainer}>
                    <StyledDropdownMenu style={styleItemsContainer}>
                        {list.map((li) => {
                            if (!!renderItem) {
                                return renderItem(li);
                            }
                            return (
                                <DropdownMenuItem
                                    {...li}
                                    onClickHandler={onClickHandler}
                                />
                            );
                        })}
                    </StyledDropdownMenu>
                </StyledDropdownContainer>,
                window.document.body
            );
        } else {
            return null;
        }
    };

    const onFocusHandler = () => {
        if (!disabled) {
            if (!toggleOpen) {
                setIsOpen(true);
            } else {
                toggleOpen();
            }
        }
    };

    return (
        <>
            <StyledDropdown
                style={style}
                ref={dropdownRef}
                onClick={onFocusHandler}
            >
                {children}
            </StyledDropdown>
            {renderOverlay()}
        </>
    );
};
