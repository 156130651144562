import { isAxiosError } from 'axios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../types/error';
import { IProfile } from '@/types/profile';
import { apiClient } from '../../api/apiClient';

interface IInitialState {
    profile?: IProfile;
    team?: string | null;
    isLoadingProfile: boolean;
    errorProfile: IError;
    isLoadingTeam: boolean;
    errorTeam: IError;
}

const initialState: IInitialState = {
    profile: undefined,
    isLoadingProfile: false,
    errorProfile: {
        code: undefined,
        message: undefined,
    },
    isLoadingTeam: false,
    errorTeam: {
        code: undefined,
        message: undefined,
    },
};

export const getProfile = createAsyncThunk<
    IProfile[],
    void,
    { rejectValue: IError }
>('profileSlice/getProfile', async (_, { rejectWithValue }) => {
    try {
        const response = await apiClient.get('/api/v1/profiles/', {
            requestName: 'getProfile',
        });
        const data = await response.data.results;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const createTeam = createAsyncThunk<
    { team: string },
    void,
    { rejectValue: IError }
>('profileSlice/createTeam', async (_, { rejectWithValue }) => {
    try {
        const response = await apiClient.get('/api/v1/profiles/create_team/', {
            requestName: 'createTeam',
        });
        const data = await response.data;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const quitTeam = createAsyncThunk<void, void, { rejectValue: IError }>(
    'profileSlice/quitTeam',
    async (_, { rejectWithValue }) => {
        try {
            await apiClient.get('/api/v1/profiles/quit_team/', {
                requestName: 'quitTeam',
            });
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);

export const joinTeam = createAsyncThunk<void, string, { rejectValue: IError }>(
    'profileSlice/joinTeam',
    async (teamId, { rejectWithValue, dispatch }) => {
        try {
            await apiClient.post('/api/v1/profiles/join_team/', {
                team: teamId,
            });
            dispatch(createTeam());
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);

export const profileSlice = createSlice({
    name: 'profileSlice',
    initialState,
    reducers: {
        changeUsername(state, action: PayloadAction<string>) {
            if (!state.profile) return;
            state.profile.user = action.payload;
        },
        getProfileFetching(state) {
            state.isLoadingProfile = true;
            state.errorProfile = {
                code: undefined,
                message: undefined,
            };
        },
        getProfileSaccess(state, action: PayloadAction<IProfile[]>) {
            state.profile = action.payload[0];
            state.team = action.payload[0].team;
            state.isLoadingProfile = false;
            state.errorProfile = {
                code: undefined,
                message: undefined,
            };
        },
        getProfileFailure(state, action) {
            state.isLoadingProfile = false;
            state.errorProfile = action.payload;
        },
        createTeamFetching(state) {
            state.isLoadingTeam = true;
            state.errorTeam = {
                code: undefined,
                message: undefined,
            };
        },
        createTeamSaccess(state, action: PayloadAction<{ team: string }>) {
            state.team = action.payload.team;
            state.isLoadingTeam = false;
            state.errorTeam = {
                code: undefined,
                message: undefined,
            };
        },
        createTeamFailure(state, action) {
            state.isLoadingTeam = false;
            state.errorTeam = action.payload;
        },
        quitTeamFetching(state) {
            state.isLoadingTeam = true;
            state.errorTeam = {
                code: undefined,
                message: undefined,
            };
        },
        quitTeamSaccess(state) {
            state.team = null;
            state.isLoadingTeam = false;
            state.errorTeam = {
                code: undefined,
                message: undefined,
            };
        },
        quitTeamFailure(state, action) {
            state.isLoadingTeam = false;
            state.errorTeam = action.payload;
        },
        //
        joinTeamFetching(state) {
            state.isLoadingTeam = true;
            state.errorTeam = {
                code: undefined,
                message: undefined,
            };
        },
        joinTeamSaccess(state) {
            createTeam();
            state.isLoadingTeam = false;
            state.errorTeam = {
                code: undefined,
                message: undefined,
            };
        },
        joinTeamFailure(state, action) {
            state.isLoadingTeam = false;
            state.errorTeam = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(joinTeam.pending, (state) => {
            state.isLoadingTeam = true;
            state.errorTeam = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(joinTeam.fulfilled, (state) => {
            state.isLoadingTeam = false;
            state.errorTeam = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(joinTeam.rejected, (state, action) => {
            state.isLoadingTeam = false;
            state.errorTeam = action.payload as IError;
        });
        builder.addCase(quitTeam.pending, (state) => {
            state.isLoadingTeam = true;
            state.errorTeam = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(quitTeam.fulfilled, (state, action) => {
            state.team = null;
            state.isLoadingTeam = false;
            state.errorTeam = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(quitTeam.rejected, (state, action) => {
            state.isLoadingTeam = false;
            state.errorTeam = action.payload as IError;
        });
        ///
        builder.addCase(getProfile.pending, (state) => {
            state.isLoadingProfile = true;
            state.errorProfile = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getProfile.fulfilled, (state, action) => {
            if (!!action.payload && action.payload.length) {
                state.profile = action.payload[0];
                state.team = action.payload[0].team;
            }
            state.isLoadingProfile = false;
            state.errorProfile = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getProfile.rejected, (state, action) => {
            state.isLoadingProfile = false;
            state.errorProfile = action.payload as IError;
        });
        builder.addCase(createTeam.pending, (state) => {
            state.isLoadingTeam = true;
            state.errorTeam = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(createTeam.fulfilled, (state, action) => {
            state.team = action.payload.team;
            state.isLoadingTeam = false;
            state.errorTeam = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(createTeam.rejected, (state, action) => {
            state.isLoadingTeam = false;
            state.errorTeam = action.payload as IError;
        });
    },
});

export default profileSlice.reducer;
