import styled from 'styled-components';
import { typography } from '@/constants';

export const StyledForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    box-sizing: border-box;
    padding-bottom: 24px;
    > span.or {
        position: absolute;
        left: -32px;
        top: 64px;
        ${typography.title.S};
    }
`;

export const StyledFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    > p.taskDiscription {
        ${typography.text.M};
    }
`;
