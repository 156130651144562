import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { ITokensData } from '@/types';
import { authSlice } from '@/store/reducers/AuthSlice';

export const useAuth = () => {
    const { auth: rootAuth } = useAppSelector((state) => state.authReducer);
    const dispath = useAppDispatch();
    const auth = useMemo<boolean>(() => {
        if (!rootAuth) {
            if (typeof localStorage.getItem('token') === 'string') {
                const tokens: ITokensData = JSON.parse(
                    localStorage.getItem('token') as string
                );
                dispath(authSlice.actions.authFetchingAccess(tokens));
                return true;
            }
            return false;
        }
        return rootAuth;
    }, [rootAuth, dispath]);

    return auth;
};
