import { useMemo } from 'react';
import { FlagIcon, StarIcon, TaskIcon, TextItem } from '@/components';
import { useAppSelector } from '@/hooks/redux';
import { baseColors } from '@/constants';
import {
    StyledPointContainer,
    StyledTotalCountContainer,
    StyledDescription,
    StyledDescriptionContainer,
    StyledCount,
    StyledIconContainer,
    StyledStatisticCard,
    StyledCardContainer,
    StyledStatistics,
    StyledChampionBloc,
} from './styled';

interface IProps {
    firstBlood?: { user: string; user_id: number };
    lastSolved?: { user: string; user_id: number };
}

export const Statistics = ({ firstBlood, lastSolved }: IProps) => {
    const { tasks } = useAppSelector((state) => state.TaskSlice);
    const { highlights } = useAppSelector((state) => state.FlagsSlice);

    const flagCount = useMemo(() => {
        if (highlights) {
            const points = highlights.found.reduce((acc, curr) => {
                acc += Number(curr.points) || 0;
                return acc;
            }, 0);
            const allPoints =
                points +
                highlights.not_found.reduce((acc, curr) => {
                    acc += Number(curr.points) || 0;
                    return acc;
                }, 0);
            return {
                completed: highlights.found.length,
                all: highlights.found.length + highlights.not_found.length,
                points,
                allPoints,
            };
        }
        return {
            completed: 0,
            all: 0,
            points: 0,
            allPoints: 0,
        };
    }, [highlights]);

    const taskCount = useMemo(() => {
        if (tasks) {
            return tasks.reduce(
                (acc, curr) => {
                    acc.all += 1;
                    acc.allPoints += curr.points;
                    if (curr.is_completed) {
                        acc.completed += 1;
                        acc.points += curr.points;
                    }
                    return acc;
                },
                { completed: 0, all: 0, points: 0, allPoints: 0 }
            );
        }
        return { completed: 0, all: 0, points: 0, allPoints: 0 };
    }, [tasks]);

    const totalCount = useMemo(() => {
        return {
            points: taskCount.points + flagCount.points,
            allPoints: taskCount.allPoints + flagCount.allPoints,
        };
    }, [taskCount, flagCount]);

    return (
        <StyledStatistics>
            <StyledTotalCountContainer>
                <StyledDescription style={{ color: baseColors.warning() }}>
                    Total / All
                </StyledDescription>
                <StyledCount
                    style={{ color: baseColors.warning() }}
                >{`${totalCount.points}/${totalCount.allPoints}`}</StyledCount>
                <StyledPointContainer>
                    <StarIcon variant="S" fill={baseColors.warning()} />
                    <StyledDescription style={{ color: baseColors.warning() }}>
                        points for lab
                    </StyledDescription>
                </StyledPointContainer>
            </StyledTotalCountContainer>
            <StyledCardContainer>
                <StyledStatisticCard>
                    <StyledIconContainer>
                        <TaskIcon />
                    </StyledIconContainer>
                    <StyledCount>{`${taskCount.completed}/${taskCount.all}`}</StyledCount>
                    <StyledDescriptionContainer>
                        <StyledDescription>
                            Completed / All tasks
                        </StyledDescription>
                        <StyledPointContainer>
                            <StarIcon variant="S" fill={baseColors.warning()} />
                            <StyledDescription
                                style={{ color: baseColors.warning() }}
                            >{`${taskCount.points} points`}</StyledDescription>
                        </StyledPointContainer>
                    </StyledDescriptionContainer>
                </StyledStatisticCard>
                <StyledStatisticCard>
                    <StyledIconContainer>
                        <FlagIcon />
                    </StyledIconContainer>
                    <StyledCount>{`${flagCount.completed}/${flagCount.all}`}</StyledCount>
                    <StyledDescriptionContainer>
                        <StyledDescription>
                            Finded / All flags
                        </StyledDescription>
                        <StyledPointContainer>
                            <StarIcon variant="S" fill={baseColors.warning()} />
                            <StyledDescription
                                style={{ color: baseColors.warning() }}
                            >{`${flagCount.points} points`}</StyledDescription>
                        </StyledPointContainer>
                    </StyledDescriptionContainer>
                </StyledStatisticCard>
            </StyledCardContainer>
            <StyledChampionBloc>
                <TextItem
                    styleKey={{ textAlign: 'center', width: '100%' }}
                    styleValue={{ textAlign: 'center', width: '100%' }}
                    valueSize="S"
                    valueText={firstBlood ? firstBlood.user : '–'}
                    keySize="S"
                    keyText="First blood"
                    direction="column"
                    link={
                        firstBlood
                            ? `/scoreboard/all_users/${firstBlood?.user_id}`
                            : undefined
                    }
                />
                <TextItem
                    styleKey={{ textAlign: 'center', width: '100%' }}
                    styleValue={{ textAlign: 'center', width: '100%' }}
                    valueSize="S"
                    valueText={lastSolved ? lastSolved.user : '–'}
                    keySize="S"
                    keyText="Last solved"
                    direction="column"
                    link={
                        lastSolved
                            ? `/scoreboard/all_users/${lastSolved?.user_id}`
                            : undefined
                    }
                />
            </StyledChampionBloc>
        </StyledStatistics>
    );
};
