import { Routes, Route, Navigate } from 'react-router-dom';
import { SignInForm } from '@/pages/AuthPages/components/SignInForm/SignInForm';
import { SignUpForm } from '@/pages/AuthPages/components/SignUpForm/SignUpForm';
import { WriteToUs } from '@/pages/AuthPages/components/WriteToUs/WriteToUs';
import { Labs } from '@/pages';
import {
    Scoreboard,
    ScoreboardAllUsers,
    ScoreboardDetail,
    ScoreboardPrivate,
} from '@/pages/ScoreboardV';
import { Price } from '@/pages/Price/Price';
import { Lab as LabWithScheme } from '@/pages/LabWithScheme/Lab';
import {
    Flags,
    Tasks,
    Information,
    Walkthroughs,
} from '@/pages/LabWithScheme/pages';
import { Workshop, Invite } from '@/pages/Workshop';
import { LabContextModule } from '@/pages/LabWithScheme/context/LabContextModule';
import { LAB_TYPE } from '@/constants';
import { StartPage, IsWorkshop, IsAuth, IsVerify } from './components';

export const AppRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<StartPage />}>
                <Route index element={<Navigate to={'labs'} replace />} />
                <Route path="labs" element={<Labs />} />
                <Route
                    path="labs/:template_id"
                    element={
                        <LabContextModule type={LAB_TYPE.DEFAULT}>
                            <LabWithScheme />
                        </LabContextModule>
                    }
                >
                    <Route
                        index
                        element={<Navigate to="information" replace />}
                    />
                    <Route path="information" element={<Information />} />
                    <Route path="tasks" element={<Tasks />} />
                    <Route path="flags" element={<Flags />} />
                    <Route path="walkthroughs" element={<Walkthroughs />} />
                </Route>
                <Route path="scoreboard" element={<Scoreboard />}>
                    <Route index element={<Navigate to="all_users" />} />
                    <Route path="all_users/*" element={<ScoreboardAllUsers />}>
                        <Route path=":scoreId" element={<ScoreboardDetail />} />
                    </Route>
                    <Route path="private" element={<ScoreboardPrivate />}>
                        <Route
                            path=":privateId"
                            element={<ScoreboardDetail />}
                        />
                    </Route>
                </Route>
                <Route element={<IsWorkshop />}>
                    <Route path="workshops" element={<Workshop />} />
                </Route>
                <Route path="prices" element={<Price />} />
            </Route>
            <Route path="auth" element={<IsAuth />}>
                <Route index element={<Navigate to="signin" replace />} />
                <Route path="signin" element={<SignInForm />} />
                <Route path="signup" element={<SignUpForm />} />
                <Route path="write_to_us" element={<WriteToUs />} />
            </Route>
            <Route path="verify/:verify_uuid" element={<IsVerify />} />
            <Route path="workshop" element={<Navigate to="invite" replace />} />
            <Route path="workshop/invite" element={<Invite />} />
            <Route path="workshop/invite/:code" element={<Invite />} />
            <Route
                path="workshoplab/:uuid"
                element={
                    <LabContextModule type={LAB_TYPE.WORKSHOP}>
                        <LabWithScheme />
                    </LabContextModule>
                }
            >
                <Route index element={<Navigate to="information" replace />} />
                <Route path="information" element={<Information />} />
                <Route path="tasks" element={<Tasks />} />
                <Route path="flags" element={<Flags />} />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
};
