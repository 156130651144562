import { memo } from 'react';
import { ArrowUpIcon, TextItem, ArrowDownIcon } from '@/components';
import { baseColors } from '@/constants';
import { IStatisticFromLab } from '@/types';
import {
    StyledHelpBar,
    StyledIsland,
    StyledStatistic,
    StyledFactoid,
    StyledCountContainer,
    StyledStatisticBloc,
    StyledDescription,
    StyledSeparator,
} from './styled';
import { Mentor, Statistics } from './components';

interface IProps {
    messageFetching: boolean;
    message?: string;
    // statistic?: IStatisticFromLab;
    // statisticFetching: boolean;
    firstBlood?: { user: string; user_id: number };
    lastSolved?: { user: string; user_id: number };
}

export const HelpBar = memo((props: IProps) => {
    return (
        <StyledHelpBar>
            <StyledIsland>
                <Mentor {...props} />
            </StyledIsland>
            <StyledIsland>
                <Statistics {...props} />
            </StyledIsland>
            <StyledIsland
                style={{ height: '400px', minHeight: '400px' }}
            ></StyledIsland>
        </StyledHelpBar>
    );
});
