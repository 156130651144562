import { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Select, Button } from '@/components';
import { SideBarContext } from '@/components/SideBar/context';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { LAB_TYPE } from '@/constants';
import {
    checkTasksThunk,
    checkTasksWsThunk,
} from '@/store/reducers/TasksSlice/AsyncThunks';
import { TerminalContext } from '../../context/terminalContext';
import { LabContext } from '@/pages/LabWithScheme/context/labContext';
import { VMTerminal } from '../../../';
import { StyledForm, StyledFieldContainer } from './styled';
import { IVmOption } from '../../types';

export const Terminal = () => {
    const dispath = useAppDispatch();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { currentVmOption, setVmOption, taskOptions, vmOptions } =
        useContext(TerminalContext);
    const { setOpenCellId } = useContext(SideBarContext);
    const { type } = useContext(LabContext);
    const { lab } = useAppSelector((state) => state.LabWithSchemeSlice);

    return (
        <StyledForm>
            <Select
                options={vmOptions}
                label="Select VM"
                defaultValue={currentVmOption?.id}
                onChange={(opt) => {
                    if (setVmOption) setVmOption(opt as IVmOption);
                }}
            />
            <span className="or">OR</span>
            <StyledFieldContainer>
                <Select
                    options={taskOptions.filter((t) => !!t.vmId)}
                    label="Select task"
                    defaultValue={currentVmOption?.id}
                    onChange={(opt) => {
                        if (setVmOption) setVmOption(opt as IVmOption);
                    }}
                />
                {currentVmOption?.description && (
                    <p className="taskDiscription">
                        {currentVmOption?.description}
                    </p>
                )}
            </StyledFieldContainer>

            {currentVmOption && <VMTerminal uuid={currentVmOption.vmId} />}
            {currentVmOption &&
                taskOptions.findIndex((to) => to.id === currentVmOption.id) >=
                    0 && (
                    <Button
                        onClick={() => {
                            if (lab?.uuid) {
                                const checkRequest = {
                                    uuid: lab?.uuid,
                                    cardId: Number(
                                        String(currentVmOption.id).replace(
                                            /_task$/,
                                            ''
                                        )
                                    ),
                                };
                                dispath(
                                    type === LAB_TYPE.DEFAULT
                                        ? checkTasksThunk(checkRequest)
                                        : checkTasksWsThunk(checkRequest)
                                );
                                navigate(
                                    pathname.replace(/[aA-zZ]*$/, 'tasks')
                                );
                                setOpenCellId(undefined);
                            }
                        }}
                    >
                        Check task
                    </Button>
                )}
        </StyledForm>
    );
};
