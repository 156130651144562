import styled from 'styled-components';
import { baseColors } from '@/constants';

export const StyledLab = styled.div`
    background: ${baseColors.levelOne()};
    width: 100%;
`;

// export const StyledContainer = styled.div`
//     width: 100%;
//     height: calc(100% - 48px);
//     position: relative;
//     overflow: hidden;
//     display: flex;
// `;

export const StyledContainer = styled.div`
    width: 100%;
    height: calc(100% - 48px);
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-columns: 320px 1fr 64px;
`;
