import { css } from 'styled-components';

export const baseColors = {
    primary: (alpha: number = 1) => `rgba(20, 90, 219, ${alpha})`,
    warning: (alpha: number = 1) => `rgba(223, 181, 30, ${alpha})`,
    critical: (alpha: number = 1) => `rgba(222, 98, 28, ${alpha})`,
    ok: (alpha: number = 1) => `rgba(24, 195, 72, ${alpha})`,
    error: (alpha: number = 1) => `rgba(206, 34, 75, ${alpha})`,
    disabled: (alpha: number = 1) => `rgba(116, 116, 116, ${alpha})`,
    bckg: (alpha: number = 1) => `rgba(18, 13, 33, ${alpha})`,
    card: (alpha: number = 1) => `rgba(32, 28, 46, ${alpha})`,
    board: (alpha: number = 1) => `rgba(45, 42, 59, ${alpha})`,
    hint: (alpha: number = 1) => `rgba(196, 195, 200, ${alpha})`,
    black: (alpha: number = 1) => `rgba(18, 13, 33, ${alpha})`,
    white: (alpha: number = 1) => `rgba(255, 255, 255, ${alpha})`,
    levelThree: (alpha: number = 1) => `rgba(54, 49, 66, ${alpha})`,
    levelTwo: (alpha: number = 1) => `rgba(45, 42, 59, ${alpha})`,
    levelOne: (alpha: number = 1) => `rgba(32, 28, 46, ${alpha})`,
    link: (alpha: number = 1) => `rgba(80, 140, 252, ${alpha})`,
};

export const baseColorHover = {
    card: (alpha: number = 1) => `rgba(29, 25, 43, ${alpha})`,
    board: (alpha: number = 1) => `rgba(40, 36, 54, ${alpha})`,
    link: (alpha: number = 1) => `rgba(160, 193, 255, ${alpha})`,
    levelOne: (alpha: number = 1) => baseColors.levelTwo(alpha),
    levelTwo: (alpha: number = 1) => baseColors.levelThree(alpha),
    primary: (alpha: number = 1) => `rgba(13, 56, 135, ${alpha})`,
    white: (alpha: number = 1) => `rgba(224, 224,24, ${alpha})`,
    error: (alpha: number = 1) => `rgba(162, 27, 59, ${alpha})`,
};

export const shadow = {
    levelTwo: {
        levelOne: css`
            box-shadow: 0px 2px 12px 0px ${baseColors.black(0.24)};
        `,
    },
};

export const baseBodyStyle = css`
    font-style: normal;
    font-weight: 400;
    color: #fff;
`;

export const baseTitleStyle = css`
    font-style: normal;
    font-weight: 600;
    color: #fff;
`;

export const typography = {
    text: {
        XXXXL: css`
            ${baseBodyStyle};
            font-size: 40px;
            line-height: 52px;
        `,
        XXXL: css`
            ${baseBodyStyle};
            font-size: 32px;
            line-height: 40px;
        `,
        XXL: css`
            ${baseBodyStyle};
            font-size: 24px;
            line-height: 32px;
        `,
        XL: css`
            ${baseBodyStyle};
            font-size: 20px;
            line-height: 28px;
        `,
        L: css`
            ${baseBodyStyle};
            font-size: 16px;
            line-height: 24px;
        `,
        M: css`
            ${baseBodyStyle};
            font-size: 14px;
            line-height: 20px;
        `,
        S: css`
            ${baseBodyStyle};
            font-size: 12px;
            line-height: 16px;
        `,
    },
    title: {
        XXXXL: css`
            ${baseTitleStyle};
            font-size: 40px;
            line-height: 52px;
        `,
        XXXL: css`
            ${baseTitleStyle};
            font-size: 32px;
            line-height: 40px;
        `,
        XXL: css`
            ${baseTitleStyle};
            font-size: 24px;
            line-height: 32px;
        `,
        XL: css`
            ${baseTitleStyle};
            font-size: 20px;
            line-height: 28px;
        `,
        L: css`
            ${baseTitleStyle};
            font-size: 16px;
            line-height: 24px;
        `,
        M: css`
            ${baseTitleStyle};
            font-size: 14px;
            line-height: 20px;
        `,
        S: css`
            ${baseTitleStyle};
            font-size: 12px;
            line-height: 16px;
        `,
    },
};

export const baseLinkStyle = css`
    text-decoration: none;
    color: ${baseColors.link()};
    &:hover {
        color: ${baseColorHover.link()};
    }
`;

export const link = {
    text: {
        XXXL: css`
            ${typography.text.XXXL};
            ${baseLinkStyle};
        `,
        XXL: css`
            ${typography.text.XXL};
            ${baseLinkStyle};
        `,
        XL: css`
            ${typography.text.XL};
            ${baseLinkStyle};
        `,
        L: css`
            ${typography.text.L};
            ${baseLinkStyle};
        `,
        M: css`
            ${typography.text.M};
            ${baseLinkStyle};
        `,
        S: css`
            ${typography.text.S};
            ${baseLinkStyle};
        `,
    },
    title: {
        XXXL: css`
            ${typography.title.XXXL};
            ${baseLinkStyle};
        `,
        XXL: css`
            ${typography.title.XXL};
            ${baseLinkStyle};
        `,
        XL: css`
            ${typography.title.XL};
            ${baseLinkStyle};
        `,
        L: css`
            ${typography.title.L};
            ${baseLinkStyle};
        `,
        M: css`
            ${typography.title.M};
            ${baseLinkStyle};
        `,
        S: css`
            ${typography.title.S};
            ${baseLinkStyle};
        `,
    },
};

export const scrollStyles = css`
    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background: ${baseColors.white(0)};
        background-clip: content-box;
        border: 1px solid transparent;
        border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb {
        background: ${baseColors.white(0.06)};
        background-clip: content-box;
        border: 1px solid transparent;
        border-radius: 20px;

        &:hover {
            border: 0;
            background: ${baseColors.white(0.25)};
        }
    }
`;
