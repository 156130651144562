import React, { ReactNode } from 'react';
import { TooltipProps } from '@/components/Tooltip/types';

export enum SHAPE {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    GHOST = 'ghost',
}

export type ButtonTooltipConfigType = Omit<TooltipProps, 'children'>;

export interface IButton {
    tooltip?: ButtonTooltipConfigType;
    type?: 'submit' | 'button';
    icon?: ReactNode;
    style?: React.CSSProperties;
    children?: ReactNode;
    view?: SHAPE;
    disabled?: boolean;
    onClick?: (e: any) => void;
}
